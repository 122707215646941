import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ADD_ADMIN_URI, DELETE_ADMIN_URI, GET_ADMINS_URI } from 'src/constants';
import AuthenitcatedUser from 'src/typings/AuthenticatedUser';
import { AddAdminDialogComponent } from './add-admin-dialog/add-admin-dialog.component';
import * as _ from 'lodash';
import { AreYouSureComponent } from 'src/app/are-you-sure/are-you-sure.component';

interface Admin {
    name: string;
    id: string;
}
@Component({
  selector: 'app-manage-admins',
  templateUrl: './manage-admins.component.html',
  styleUrls: ['./manage-admins.component.scss']
})
export class ManageAdminsComponent implements OnInit {
    displayedColumns = ["id", "name", "actions"]
    admins: BehaviorSubject<Admin[]>
    user: AuthenitcatedUser | null;
    selectedToRemove: string = "";

    constructor(private http: HttpClient, public dialog: MatDialog, private auth: AuthService) { }

    ngOnInit(): void {
        this.auth.getAuthenticatedUser().subscribe(user => {
            if(user) {
                this.user = user;
            }
        });
        this.http.get(GET_ADMINS_URI).subscribe((data: any) => {
            this.admins = new BehaviorSubject<Admin[]>(data);
        });
    }

    openAddAdminDialog() {
        const ref = this.dialog.open(AddAdminDialogComponent);
        ref.afterClosed().subscribe(id => {
            this.http.post(ADD_ADMIN_URI, {id: id}).subscribe(admin => {
                const newAdmins: any = _.concat(this.admins.getValue(), admin);
                this.admins.next(newAdmins);
            });
        });
    }

    openAreYouSure(id: string) {
        this.selectedToRemove = id;
        const ref = this.dialog.open(AreYouSureComponent);
        ref.afterClosed().subscribe(isSure => {
            if(isSure) {
                this.http.delete(DELETE_ADMIN_URI, {body: {id: this.selectedToRemove}}).subscribe(() => {
                    const admins = this.admins.getValue();
                    for(let i in admins) {
                        let _i = parseInt(i);
                        const _id =admins[i].id;
                        if(_id === this.selectedToRemove) {
                            admins.splice(_i, 1);
                        }
                    }
                    this.admins.next(admins);
                });
            }
        })
    }

}
