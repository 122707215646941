import {environment} from './environments/environment';
export const API_URI = environment.production ? "https://api.cmgstudios.net/logging-panel/" : "http://localhost:3030/";
export const DISCORD_LOGIN_URL = API_URI + "auth/login";
export const AUTH_STATE_URI = API_URI + "auth/state";
export const LOGOUT_URI = API_URI + "auth/logout";
export const CREATE_API_TOKEN_URI = API_URI + "api/apikeys/create";
export const DELETE_API_TOKEN_URI = API_URI + "api/apikeys/delete";
export const GET_API_TOKENS = API_URI + "api/apikeys/fetch";
export const GET_ADMINS_URI = API_URI + "api/admins/fetch";
export const ADD_ADMIN_URI = API_URI + "api/admins/add";
export const DELETE_ADMIN_URI = API_URI + "api/admins/delete";
export const FETCH_STREAM_NAMES_URI = API_URI + "streams/list";
export const FETCH_STREAM_URI = API_URI + "streams/fetch";
export const SAVE_STREAM_URI = API_URI + "streams/save";
export const CREATE_STREAM_URI = API_URI + "streams/create";
export const DELETE_STREAM_URI = API_URI + "streams/delete";
export const GET_SETTINGS_URI = API_URI + "api/settings/fetch"
export const SAVE_SETTINGS_URI = API_URI + "api/settings/save"