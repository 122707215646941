import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DISCORD_LOGIN_URL } from 'src/constants';
import { AuthService } from '../auth/auth.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(private authService: AuthService, private router: Router) { }

    ngOnInit(): void {
        this.authService.getAuthenticatedUser().subscribe(user => {
            if(user){
                this.router.navigate(["/dashboard"])
            }
        })
    }

    onLoginClick() {
        window.location.href = DISCORD_LOGIN_URL;
    }
}
