import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CREATE_API_TOKEN_URI, DELETE_API_TOKEN_URI, GET_API_TOKENS } from 'src/constants';
import AuthenitcatedUser from 'src/typings/AuthenticatedUser';
import { NewApiKeyDialogComponent } from './new-api-key-dialog/new-api-key-dialog.component';
import * as _ from 'lodash';
import { parse } from 'path';



interface ApiKey {
    token: string,
    name: string
}
@Component({
  selector: 'app-manage-api-keys',
  templateUrl: './manage-api-keys.component.html',
  styleUrls: ['./manage-api-keys.component.scss']
})
export class ManageApiKeysComponent implements OnInit {

    displayedColumns = ["name", "actions"]
    apiKeys: BehaviorSubject<ApiKey[]>;
    user: AuthenitcatedUser | null;

    constructor(private http: HttpClient, public dialog: MatDialog, private auth: AuthService) { }

    ngOnInit(): void {
        this.auth.getAuthenticatedUser().subscribe(user => {
            if(user) {
                this.user = user;
            }
        });

        this.http.get(GET_API_TOKENS).subscribe(res => {
            if(res) {
                this.apiKeys = new BehaviorSubject<ApiKey[]>(res as any);
            }
        })
    }

    onCopyClick(token: string) {
        navigator.clipboard.writeText(token);
    }

    onRemoveClick(token: string) {
        this.http.delete(DELETE_API_TOKEN_URI, {body: {token:token}}).subscribe(() => {
            const tokens = this.apiKeys.getValue();
            for(let i in tokens) {
                let _i = parseInt(i);
                const _token = tokens[i].token;
                if(_token === token) {
                    tokens.splice(_i, 1);
                }
            }
            this.apiKeys.next(tokens)
        });
    }

    newApiKeyClick() {
        const ref = this.dialog.open(NewApiKeyDialogComponent);
        ref.afterClosed().subscribe(result => {
            if(result) {
                this.http.post(CREATE_API_TOKEN_URI, {
                    api_key_name: result
                }).subscribe(res => {
                    res = res as any;
                    const newKeys: any = _.concat(this.apiKeys.getValue(), res);
                    this.apiKeys.next(newKeys);
                });
            }
        });
    }
}
