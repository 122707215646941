<h1 mat-dialog-title>Create a new API token.</h1>
<div mat-dialog-content>
  <p>Name of the token (e.g Server 1, Server 2)</p>
  <mat-form-field appearance="fill">
    <mat-label>Key Name</mat-label>
    <input matInput color="primary" [(ngModel)]="keyName">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="remove-button" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button [mat-dialog-close]="keyName" class="add-button" cdkFocusInitial>Add</button>
</div>