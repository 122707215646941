import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'englishify'
})
export class EnglishifyPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {   
        let englishName = "";   
        const splitValue = value.split("-");
        for (let str of splitValue) {
            let eng = str[0].toUpperCase() + str.substring(1) + " ";
            englishName += eng;
        }
        return englishName;
    }
}
