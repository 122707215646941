<div class="container-fluid">

    <!-- <p>{{name}}</p> -->
    <div class="makeshift-logs-table">
        <div class="header">
            <mat-expansion-panel disabled class="header-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title id="go-back">
                        Timestamp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
        </div>
        <cdk-virtual-scroll-viewport id="viewport" itemSize="30" class="logs-container mat-elevation-z8" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="50" [infiniteScrollThrottle]="1000" (scrolled)="onScroll()" >
            <div class="log" *cdkVirtualFor="let log of logs">
                <span>
                    {{log.createdAt | streamdate}}  
                </span>
                <span class="content-clm">
                    {{log.content}}
                </span>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
    <!-- </div> -->
    <div class="side-options">
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel disabled class="side-option-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title id="go-back">
                        {{name | englishify}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
            <mat-expansion-panel class="side-option-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Filter
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field  class="select-stream" appearance="fill">
                    <mat-label>Search</mat-label>
                    <input matInput [ngModel]="contentSearchTerm" (ngModelChange)="onSearchTermChange($event)" (keyup.enter)="onEnter()">
                    <mat-hint>Press enter to search</mat-hint>
                </mat-form-field>
                <hr>
                <mat-form-field appearance="fill">
                    <mat-label>Label</mat-label>
                    <mat-select [ngModel]="labelKey" (ngModelChange)="onLabelIdChange($event)">
                      <mat-option value="none">None</mat-option>
                      <mat-option value="user_id">User ID</mat-option>
                    </mat-select>
                  </mat-form-field>
                <mat-form-field *ngIf="labelKey != 'none'" class="select-stream" appearance="fill">
                    <mat-label>Equals</mat-label>
                    <input matInput [ngModel]="labelSearchTerm" (ngModelChange)="onLabelSearchTermChange($event)" (keyup.enter)="onEnter()">
                    <mat-hint>Press enter to search</mat-hint>
                </mat-form-field>
            </mat-expansion-panel>
            <mat-expansion-panel disabled (click)="goBack()" class="side-option-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title id="go-back"> 
                        Go Back
                    </mat-panel-title>
                    <mat-icon id="go-back">exit_to_app</mat-icon>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>


