import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { pki } from 'node-forge';
import { Observable } from 'rxjs';
import { AUTH_STATE_URI, LOGOUT_URI } from 'src/constants';
import AuthenitcatedUser from 'src/typings/AuthenticatedUser';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private publicKey: string = `-----BEGIN PUBLIC KEY-----
    MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgEWOUCiRXtvGEr9VMPAUDsONCzaZ
    bg1wNTfgi/QpsrT78NIIM2zNXFJnt7K38SwADGl4IE2QPqzAhHg1hIetO2HG0C4u
    VC9OBqgxXkkaMhmGu4mzvt8V53Ud8Q6fMVEQDWJd6+yVRMXV5xEeLDH8LurgKJqG
    oCNO89A5wDIj478BAgMBAAE=
    -----END PUBLIC KEY-----`;
    rsa: any;
    private user: AuthenitcatedUser | null | undefined;
    private token: string = "";
    constructor(private http: HttpClient, private cookieService: CookieService) { 
        this.rsa = pki.publicKeyFromPem(this.publicKey);
    }

    getToken(): string {
        return this.token;
    }

    isLoggedIn()  {
        return this.getAuthenticatedUser()
    }

    private fetchAuthenticatedUser(): Observable<AuthenitcatedUser> {
        return this.http.get<AuthenitcatedUser>(AUTH_STATE_URI, {withCredentials:true});
    }

    getAuthenticatedUser(): Observable<AuthenitcatedUser> {
        if(!this.user) {
            const user = this.fetchAuthenticatedUser();
            let sub = user.subscribe(_user => {
                this.user = _user;
                this.token = this.user.token;
                sub.unsubscribe();
            });
            return this.fetchAuthenticatedUser();
        } else {
            return new Observable<AuthenitcatedUser>(subscriber => {
                if(this.user) {
                    this.token = this.user.token;
                    subscriber.next(this.user);
                }
            });
        }
    }

    logout() {
        return this.http.get(LOGOUT_URI, {withCredentials:true}).subscribe(() => {
            this.user = null;
            window.location.href = "/"
        });
    }

    isAdmin() {
        if (this.user) {
            return this.user.isAdmin;
        } else {
            return false;
        }
    }
}
