<cdk-virtual-scroll-viewport tvsItemSize
                             class="wrapper mat-elevation-z2">

  <table mat-table [dataSource]="logs">

    <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell
          *matHeaderCellDef
          class="col-sm">No.</th>
      <td mat-cell
          *matCellDef="let element"
          class="col-sm">{{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>

  </table>

</cdk-virtual-scroll-viewport>