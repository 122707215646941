import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-admin-dialog',
  templateUrl: './add-admin-dialog.component.html',
  styleUrls: ['./add-admin-dialog.component.scss']
})
export class AddAdminDialogComponent implements OnInit {
  id: string;
  constructor() { }

  ngOnInit(): void {
  }

}
