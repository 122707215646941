import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-stream-dialog',
  templateUrl: './create-stream-dialog.component.html',
  styleUrls: ['./create-stream-dialog.component.scss']
})
export class CreateStreamDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
