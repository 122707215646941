import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Stream from 'src/classes/Stream';
import { GET_SETTINGS_URI, SAVE_SETTINGS_URI } from 'src/constants';

@Component({
  selector: 'app-manage-site-settings',
  templateUrl: './manage-site-settings.component.html',
  styleUrls: ['./manage-site-settings.component.scss']
})
export class ManageSiteSettingsComponent implements OnInit {
    public stream = new Stream(); // this is hacky. need to change in the future
    public allRoles = [];
    constructor(private http: HttpClient, public _snackbar: MatSnackBar) { }

    ngOnInit(): void {
        this.http.get(GET_SETTINGS_URI + "/roles").subscribe((roles: any) => {
            this.stream.roles = roles;
        });
        this.http.get(GET_SETTINGS_URI + "/roles/all").subscribe((roles: any) => {
            this.allRoles = roles;
        });
    }

    save() {
        this.http.post(SAVE_SETTINGS_URI, {
            roles: this.stream.roles.map(role => role.id)
        }).subscribe(() => {
            this._snackbar.open("Settings saved!", "Close");
        })
    }
}
