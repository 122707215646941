import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from "@angular/common";
import { AuthService } from './auth/auth.service';
import AuthenitcatedUser from 'src/typings/AuthenticatedUser';
import { HttpClient } from '@angular/common/http';
import { LOGOUT_URI } from 'src/constants';
import { MatDialog } from '@angular/material/dialog';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public isToolbarVisible: boolean = true;
    private doNotShowToolbar: string[] = [
        "",
        "/404",
        "/403",
        "/500"
    ];
    public isAdmin: boolean = false;
    private userSub: Subscription;
    constructor(private router: Router, private location: Location, private auth: AuthService, private http: HttpClient, private dialog: MatDialog) {}
    ngOnInit(): void {
        this.router.events.subscribe(val => { 
            const path = this.location.path();
            this.isToolbarVisible = !this.doNotShowToolbar.includes(path);
        });
        this.userSub = this.auth.getAuthenticatedUser().subscribe(user=> {
            if(!user && this.isToolbarVisible) {
                window.location.href = "/"
            }else if(user) {
                this.isAdmin = user.isAdmin;
            }
        });
        const hasAcceptedCookieConsent = Boolean(window.localStorage.getItem("cookie-consent"));
        if(!hasAcceptedCookieConsent) {
            this.dialog.open(CookieConsentComponent).afterClosed().subscribe(accepted => {
                if(accepted) {
                    window.localStorage.setItem("cookie-consent", "true");
                }
            });
        }
        if(environment.production) {
            console.log("init datadog");
            datadogRum.init({
                applicationId: '85c21b4e-67d0-428d-aed1-63d6fe81b62c',
                clientToken: 'pubb271bcb2204e18fc3a22181182997290',
                site: 'datadoghq.eu',
                service:'test-service',
                // Specify a version number to identify the deployed version of your application in Datadog 
                // version: '1.0.0',
                sampleRate: 100,
                trackInteractions: true
            });  
        }      
        
    }
    
    logout() {
        this.auth.logout();
    }
}
