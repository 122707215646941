<mat-form-field class="example-chip-list" appearance="fill" class="allowed-roles">
    <mat-label>Allowed Roles</mat-label>
    <mat-chip-list #chipList aria-label="Allowed Roles">
        <mat-chip *ngFor="let role of stream.roles" [selectable]="true" [removable]="true" [ngStyle]="{'color': role.colour}" (removed)="removeRole(role)">
            {{role.name}}
            <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
        </mat-chip>
      <input
        placeholder="Role"
        #roleInput
        [formControl]="roleCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let role of filteredRoles | async" [value]="role.id">
        {{role.name}}
      </mat-option>
    </mat-autocomplete>
</mat-form-field>