import { Component, OnInit } from '@angular/core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';


function u(i: number) {
    return i % 2 === 0 ? "joined the server" : "disconnected from the server"
}
const DATA = Array.from({length: 1000}, (v, i) => ({
  id: i + 1,
  name: `User ID: ${i + 1} ${u(i)}`
}));

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit {
    displayedColumns = ['id', 'name'];

    logs = new TableVirtualScrollDataSource(DATA);
    constructor() { }

    ngOnInit(): void {

    }

}
