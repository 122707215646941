import { Component, Inject, OnInit } from '@angular/core';
import { DialogRole, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-new-api-key-dialog',
  templateUrl: './new-api-key-dialog.component.html',
  styleUrls: ['./new-api-key-dialog.component.scss']
})
export class NewApiKeyDialogComponent implements OnInit {
    public keyName: string;

    constructor(public dialogRef: MatDialogRef<NewApiKeyDialogComponent>) {}


    ngOnInit(): void {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
