import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from "@angular/common";
import { JwtModule } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { MatInputModule } from '@angular/material/input'; 
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpInterceptor } from './http.interceptor';
import { EnglishifyPipe } from './stream/englishify.pipe';
import { StreamDate } from './stream/date.pipe';

import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AreYouSureComponent } from './are-you-sure/are-you-sure.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CreateStreamComponent } from './admin/manage-streams/create-stream/create-stream.component';
import { RolePickerComponent } from './admin/manage-streams/role-picker/role-picker.component';
import { CreateStreamDialogComponent } from './admin/manage-streams/create-stream-dialog/create-stream-dialog.component';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { ServerErrorComponent } from './error-pages/server-error/server-error.component';
import { ForbiddenComponent } from './error-pages/forbidden/forbidden.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ManageStreamsComponent } from './admin/manage-streams/manage-streams.component';
import { ManageApiKeysComponent } from './admin/manage-api-keys/manage-api-keys.component';
import { ManageAdminsComponent } from './admin/manage-admins/manage-admins.component';
import { ManageSiteSettingsComponent } from './admin/manage-site-settings/manage-site-settings.component';
import { AddAdminDialogComponent } from './admin/manage-admins/add-admin-dialog/add-admin-dialog.component';
import { NewApiKeyDialogComponent } from './admin/manage-api-keys/new-api-key-dialog/new-api-key-dialog.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { StreamComponent } from './stream/stream.component';
import { TestingComponent } from './testing/testing.component';





export function tokenGetter() {
  return localStorage.getItem('access_token');
}
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    StreamComponent,
    StreamDate,
    EnglishifyPipe,
    LoginComponent,
    AdminComponent,
    ManageStreamsComponent,
    ManageApiKeysComponent,
    ManageAdminsComponent,
    ManageSiteSettingsComponent,
    AddAdminDialogComponent,
    NewApiKeyDialogComponent,
    AreYouSureComponent,
    CreateStreamComponent,
    RolePickerComponent,
    CreateStreamDialogComponent,
    NotFoundComponent,
    ServerErrorComponent,
    ForbiddenComponent,
    CookieConsentComponent,
    TestingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    ScrollingModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatSidenavModule,
    MatGridListModule,
    MatChipsModule,
    MatAutocompleteModule,
    InfiniteScrollModule,
    FormsModule,
    // TableVirtualScrollModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      }
    })
    
  ],
  providers: [ 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
