<div class="admin-page">
    <div class="container">
        <div class="admin-options">
            <div class="row">
                <div class="col-sm-3 admin-option">
                    <a mat-button routerLink="/dashboard/admin/manage-streams"><mat-icon>settings_suggest</mat-icon><br>Manage Streams</a>
                </div>
                <div class="col-sm-3 admin-option">
                    <a mat-button routerLink="/dashboard/admin/manage-admins"><mat-icon>manage_accounts</mat-icon><br>Manage Admins</a>
                </div>
                <div class="col-sm-3 admin-option">
                    <a mat-button routerLink="/dashboard/admin/manage-api-keys"><mat-icon>vpn_key</mat-icon><br>Manage API Keys</a>
                </div>
                <div class="col-sm-3 admin-option">
                    <a mat-button routerLink="/dashboard/admin/manage-site-settings"><mat-icon>tune</mat-icon><br>Manage Site Settings</a>
                </div>
            </div>
        </div>
    </div>
</div>
