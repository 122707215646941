<div class="container">
    <div class="title-container">
        <h1>Stream Management</h1>
    </div>
    <div *ngIf="stream != undefined && streamIds.length > 0">
        <button mat-raised-button class="save-button" (click)="openCreateDialog()"><mat-icon>add</mat-icon>Create new stream.</button>
        <mat-form-field class="select-stream" appearance="fill">
            <mat-label>Select Stream</mat-label>
            <mat-select [ngModel]="selectedId" (ngModelChange)="onSelectChange($event)">
              <mat-option *ngFor="let id of streamIds" value="{{id}}">{{id | englishify}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field  class="select-stream" appearance="fill">
            <mat-label>Stream Description</mat-label>
            <input matInput [ngModel]="stream.stream.description"  (ngModelChange)="onDescChange($event)" placeholder="Description">
        </mat-form-field>
        <app-role-picker [stream]="stream"></app-role-picker>
        <button mat-raised-button class="save-button" (click)="save()">Save</button>
        <button mat-raised-button class="delete-button" (click)="delete()" color="warn">Delete</button>
    </div>
    <div *ngIf="!(streamIds.length > 0)" class="add-button-container">
        <p>There are currently no streams. Create one below.</p>
        <app-create-stream></app-create-stream>
    </div>

</div>

<!-- (click)="create()" -->