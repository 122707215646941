import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URI } from 'src/constants';


@Injectable({
  providedIn: 'root'
})
export class LogsService {

    constructor(private http: HttpClient) { }
    
    getLogs(name: string, batch: number, lastKey?: number, content: string = "", labelKey: string = "", labelContent: string = "") {
        return this.http.get(`${API_URI}logging/fetch/${name}?lastKey=${lastKey}&batch=${batch}&content=${content}&label=${labelKey}&labelContent=${labelContent}`);
    }

}
