import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URI } from 'src/constants';
import { Observable, BehaviorSubject } from 'rxjs';
import {tap} from 'rxjs/operators'
import {FormGroup, FormControl} from '@angular/forms';
import { LogsService } from '../logs.service';
import * as lo from 'lodash';
import CountResponse from 'src/typings/CountResponse';
import { E } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent implements OnInit {
    public name: string = "";
    public logs = new BehaviorSubject<any[]>([]);
    public displayedColumns: string[] = ["time", "content"];
    public finished = false;
    private batch = 50;
    private lastKey = 0;
    private count: number = 0;
    private scrollIndex = 1;
    public contentSearchTerm: string = "";
    public labelSearchTerm: string = "";
    public labelKey: string = "none";
    range = new FormGroup({
        start: new FormControl(),
        end: new FormControl()
    });
    constructor(private http: HttpClient, private route: ActivatedRoute, private cdr: ChangeDetectorRef, private logsService: LogsService, private router: Router) { }
    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.name = params.id;
        });
        
        this.fetchLogs();
    }

    fetchLogs(replace: boolean = false) {
        this.http.get(`${API_URI}logging/fetch/${this.name}/count?content=${this.contentSearchTerm}`).subscribe((count) => {
            const _count = count as CountResponse;
            this.count = _count.count;
            if(this.count<this.batch) {
                this.batch = this.count;
            }
            const vp = document.getElementById("viewport");
            if(vp) {
                vp.style.height = this.count*30 + "px";
            }
            this.finished = false;
            this.lastKey = 0;
            this.getLogs(replace);
        });
    }

    getLogs(replace: boolean = false) {
        if (this.finished) return;
        this.logsService.getLogs(this.name, this.batch, this.lastKey, this.contentSearchTerm, this.labelKey, this.labelSearchTerm).pipe(tap( (logs: any) => {
            this.lastKey = (logs.length)+this.lastKey;
            const newLogs = lo.slice(logs, 0, this.batch);
            const currentLogs = this.logs.getValue();

            if(this.lastKey == this.count) {
                this.finished = true;
            }
            if(!replace) {
                this.logs.next(lo.concat(currentLogs, newLogs));
            } else {
                this.logs.next(newLogs);
            }
        })).subscribe();
    }

    onLogClick(log: any) {
        console.log(log);
    }

    onScroll() {
        this.getLogs();
        // this.getLogs()
    }

    onSearchTermChange(value: string) {
        this.contentSearchTerm = value;
    }

    onLabelSearchTermChange(value: string) {
        this.labelSearchTerm = value;
    }

    onEnter() {
        this.fetchLogs(true);
    }

    onLabelSearchEnter() {
        if(this.labelSearchTerm.length > 0) {
            this.fetchLogs(true);
        }
    }

    onLabelIdChange(key: string) {
        this.labelKey = key;
    }

    goBack() {
        this.router.navigate(["/dashboard"]);
    }
}
