import { OnDestroy, Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FETCH_STREAM_URI } from 'src/constants';
import { Observable, Subscription } from 'rxjs';
import { LogStream } from 'src/typings/LogStream';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
    public logs: any[] = [];
    public streams: LogStream[] = [];
    public username: string;
    public isAdmin: boolean = false;
    private userSub: Subscription;
    public greetingMessage: string = "Good morning";
    public avatar?: string;
    panelOpenState = false;
    constructor(private http: HttpClient, public auth: AuthService, private router: Router) { 
        // this.streams = [
        //     {name: "Anti Cheat Logs", id: "ac", description: "anti cheat logs", allowed_roles: ["231231"], created_by: "me"},
        //     {name: "Anti Cheat Logs", id: "ac", description: "anti cheat logs", allowed_roles: ["231231"], created_by: "me"},
        //     {name: "Anti Cheat Logs", id: "ac", description: "anti cheat logs", allowed_roles: ["231231"], created_by: "me"},
        //     {name: "Anti Cheat Logs", id: "ac", description: "anti cheat logs", allowed_roles: ["231231"], created_by: "me"},
        //     {name: "Anti Cheat Logs", id: "ac", description: "anti cheat logs", allowed_roles: ["231231"], created_by: "me"},
        // ]
        this.getLogStreams().subscribe((streams: LogStream[]) => {
            this.streams = streams;
        });
    }
    ngOnInit(): void {
        this.userSub  = this.auth.getAuthenticatedUser().subscribe(user=> {
            this.username = user.username;
            this.avatar = user.avatarURL;
            this.isAdmin = user.isAdmin;
        });
        console.log("LOGGED IN STATE", this.auth.isLoggedIn())
        const currentHour = new Date().getHours()
        if (currentHour < 12) {
            this.greetingMessage = "Good morning"
        } else if (currentHour < 18) {
            this.greetingMessage = "Good afternoon"
        } else {
            this.greetingMessage = "Good evening"
        }
    }

    ngOnDestroy() {
        this.userSub.unsubscribe();
    }

    getLogStreams(): Observable<any> {
        return this.http.get(FETCH_STREAM_URI);
    }

    onViewClick(id: string) {
        this.router.navigate([`/dashboard/stream/${id}`]);
    }
}
