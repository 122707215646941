import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import * as _ from 'lodash';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FETCH_STREAM_URI, FETCH_STREAM_NAMES_URI, GET_SETTINGS_URI, SAVE_STREAM_URI, DELETE_STREAM_URI } from 'src/constants';
import { Observable } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AreYouSureComponent } from 'src/app/are-you-sure/are-you-sure.component';
import { CreateStreamService } from './create-stream.service';
import { CreateStreamDialogComponent } from './create-stream-dialog/create-stream-dialog.component';


@Component({
  selector: 'app-manage-streams',
  templateUrl: './manage-streams.component.html',
  styleUrls: ['./manage-streams.component.scss']
})
export class ManageStreamsComponent implements OnInit {

    public streamIds: any[] = [];
    public selectedId: string = "join-leave-logs";
    public stream: any;
    public desc: string = "";

    constructor(private http: HttpClient, private _snackBar: MatSnackBar, public dialog: MatDialog, private createStreamService: CreateStreamService) { }

    ngOnInit(): void {
        this.http.get(FETCH_STREAM_NAMES_URI).subscribe(streams => {
            this.streamIds = _.concat(this.streamIds, streams);
            if(this.streamIds.length>0) {
                this.selectedId = this.streamIds[0];
                this.fetchAndPopulate(this.selectedId);
            } 
        });
        this.createStreamService.getCreateEvent().subscribe(stream => {
            this.streamIds = _.concat(this.streamIds, stream.stream.id);
            this.stream = stream;
            this.selectedId = stream.stream.id;
        })
    }

    onSelectChange(id: string) {
        this.selectedId = id;
        this.fetchAndPopulate(id);
    }
    
    fetchAndPopulate(id: string) {
        this.http.get(FETCH_STREAM_URI + "/" + id).subscribe((streamData: any) => {
            this.desc = streamData.stream.description;
            this.stream = streamData;
        });
    }

    save() {
        this.http.post(SAVE_STREAM_URI, {
            id: this.selectedId,
            stream: this.stream,
            desc: this.desc
        }).subscribe(() => {
            this._snackBar.open("Stream settings saved!", "Close", {
                duration: 3000
            });
        })
    }

    delete() {
        this.dialog.open(AreYouSureComponent, {data: {text: "⚠️ This will delete all logs associated with this stream."}}).afterClosed().subscribe(isSure => {
            if(isSure) {
                this.http.delete(`${DELETE_STREAM_URI}/${this.selectedId}`).subscribe(() => {
                    this._snackBar.open("Stream deleted", "Close", {
                        duration: 3000
                    });
                    this.streamIds = this.streamIds.filter(id => id != this.selectedId);
                    if(this.streamIds.length==0) {
                        this.stream = undefined;
                    } else {
                        this.selectedId = this.streamIds[0];
                        this.fetchAndPopulate(this.streamIds[0])
                    }
                });
            }
        })
    }

    openCreateDialog() {
        this.dialog.open(CreateStreamDialogComponent).afterClosed().subscribe((created) => {
            if(created) {
                this._snackBar.open("Stream created.", "Close", {
                    duration: 3000
                });
            }
        })
    }

    onDescChange(desc: string) {
        this.desc = desc;
    }
      
}
