import { Component, Input, OnInit } from '@angular/core';
import Stream from 'src/classes/Stream';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CREATE_STREAM_URI } from 'src/constants';
import { CreateStreamService } from '../create-stream.service';

@Component({
  selector: 'app-create-stream',
  templateUrl: './create-stream.component.html',
  styleUrls: ['./create-stream.component.scss']
})
export class CreateStreamComponent implements OnInit {
    public stream: Stream = new Stream();
    public name: string;
    public description: string;
    public streamRoles = [];
    constructor(private http: HttpClient, private createStreamService: CreateStreamService) { }

    ngOnInit(): void {
        
    }

    onCreateClick() {
        this.http.put(CREATE_STREAM_URI, {
            name: this.name,
            roles: this.stream.roles,
            description: this.description
        }).subscribe((stream) => {
            this.createStreamService.sendCreateEvent(stream);
        });
    }

    onChange(value: any, key: string) {
        switch(key) {
            case "name": 
                this.name = value;
                break;
            case "description":
                this.description = value;
                break;
            default:
                console.log(`could not change ${key}`)
        }
    }
}
