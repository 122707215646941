import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { GET_SETTINGS_URI } from 'src/constants';
import * as _ from 'lodash';


@Component({
  selector: 'app-role-picker',
  templateUrl: './role-picker.component.html',
  styleUrls: ['./role-picker.component.scss']
})
export class RolePickerComponent implements OnInit {
    @Input("stream") stream: any;
    @Input("allRoles") allRoles: any[];
    @Input("onChange") onChange: (roles: any) => void;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    filteredRoles: Observable<any[]>;
    roleCtrl = new FormControl();
    _allRoles: any[] = [];
    @ViewChild('roleInput') roleInput: ElementRef<HTMLInputElement>;
    constructor(private http: HttpClient) { }

    ngOnInit(): void {
        if(!this.allRoles) {
            this.allRoles = this._allRoles;
        }
        this.http.get(GET_SETTINGS_URI + "/roles").subscribe(roles => {
            console.log(roles);
            this.allRoles = _.concat(this.allRoles, roles);
        });
        
        this.filteredRoles = this.roleCtrl.valueChanges.pipe(
            startWith(null),
            map((role: string | null) => role ? this._filter(role) : this.allRoles.slice())
        )
        if(!this.onChange) {
            this.onChange = () => {};
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const _role = this.allRoles.filter(role => role.name == event.option.viewValue)[0];
        if (this.stream.roles.filter((role: any) => role.name == event.option.viewValue).length == 0) {
            this.stream.roles.push(_role);
            this.onChange(this.stream.roles);
            this.roleInput.nativeElement.value = '';
            this.roleInput.nativeElement.focus()
            this.roleCtrl.setValue(null);
        }
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            this.stream.roles.push(value);
            this.onChange(this.stream.roles);
        }
        event.chipInput!.clear();
        this.roleCtrl.setValue(null);
        this.roleInput.nativeElement.focus()
    }

    private _filter(value: string) {
        const _value = value.toLowerCase();
        return this.allRoles.filter(role => role.name.toLowerCase().includes(_value));
    }

    removeRole(role: any): void {
        const index = this.stream.roles.indexOf(role);
    
        if (index >= 0) {
          this.stream.roles.splice(index, 1);
        }
        this.onChange(this.stream.roles);
    }

}
