import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateStreamService {
    private subject = new Subject<any>();
    constructor() { }
    sendCreateEvent(stream: any) {
        this.subject.next(stream);
    }

    getCreateEvent(): Observable<any> {
        return this.subject.asObservable();
    }
}
