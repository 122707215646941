<!-- Empty Commment -->
<div class="container">
    <div class="welcome-text" style="text-align: center;">
        <!-- <img src={{avatar}} alt=""> -->
        <!-- <h2>{{greetingMessage}}, {{username}}</h2> -->
        <mat-card class="card mat-elevation-z8">
            <mat-card-header>
                <img mat-card-avatar src={{avatar}} alt="">
                <mat-card-title>{{username}}</mat-card-title>
                <mat-card-subtitle>{{isAdmin ? "Adminstrator" : "User"}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <h2>{{greetingMessage}}, {{username}}</h2>
            </mat-card-content>
            <mat-card-actions>
                <a mat-button *ngIf="isAdmin" class="admin-button"  routerLink="/dashboard/admin" class="admin-button">ADMIN PANEL</a>
                <button mat-button (click)="auth.logout()" >LOGOUT</button>
            </mat-card-actions>
        </mat-card>
    </div>

    <!-- <p *ngFor="let log of logs">{{log.content}}</p> -->
    <div class="streams" *ngIf="streams.length>0">
        <mat-card *ngFor="let stream of streams" class="stream-card mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>{{stream.name}}</mat-card-title>
              <mat-card-subtitle>{{stream.description}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
              <button mat-button (click)="onViewClick(stream.id)">VIEW</button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div *ngIf="(streams.length==0)" style="text-align: center;">
        <p>There are currently no streams available for you to view. If you think this is wrong please contact a site administrator.</p>
    </div>
</div>
