import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ManageAdminsComponent } from './admin/manage-admins/manage-admins.component';
import { ManageApiKeysComponent } from './admin/manage-api-keys/manage-api-keys.component';
import { ManageSiteSettingsComponent } from './admin/manage-site-settings/manage-site-settings.component';
import { ManageStreamsComponent } from './admin/manage-streams/manage-streams.component';
import { AdminGuard } from './auth/admin.guard';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForbiddenComponent } from './error-pages/forbidden/forbidden.component';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { ServerErrorComponent } from './error-pages/server-error/server-error.component';
import { LoginComponent } from './login/login.component';
import { StreamComponent } from './stream/stream.component';
import { TestingComponent } from './testing/testing.component';

const routes: Routes = [
  {path:"dashboard", component: DashboardComponent, canActivate: [AuthGuard]},
  {path:"playground", component: TestingComponent},
  {path:"", component: LoginComponent},
  {path: "dashboard/stream/:id", component: StreamComponent, canActivate: [AuthGuard]},
  {path: "dashboard/admin", component: AdminComponent, canActivate: [AuthGuard, AdminGuard]},
  {path: "dashboard/admin/manage-admins", component: ManageAdminsComponent, canActivate: [AuthGuard, AdminGuard]},
  {path: "dashboard/admin/manage-api-keys", component: ManageApiKeysComponent, canActivate: [AuthGuard, AdminGuard]},
  {path: "dashboard/admin/manage-streams", component: ManageStreamsComponent, canActivate: [AuthGuard, AdminGuard]},
  {path: "dashboard/admin/manage-site-settings", component: ManageSiteSettingsComponent, canActivate: [AuthGuard, AdminGuard]},
  {path: "404", component: NotFoundComponent},
  {path: "403", component: ForbiddenComponent},
  {path: "500", component: ServerErrorComponent},
  {path: "**", component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
