
<mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput [ngModel]="name" required (ngModelChange)="onChange($event, 'name')" autocomplete="off" data-lpignore="true" data-form-type="text">
</mat-form-field>
<mat-form-field appearance="fill">
    <mat-label>Description</mat-label>
    <input matInput [ngModel]="description" required (ngModelChange)="onChange($event, 'description')" autocomplete="off" data-lpignore="true" data-form-type="text">
</mat-form-field>
<app-role-picker class="app-role-picker" [stream]="stream"></app-role-picker>

<button mat-raised-button [mat-dialog-close]="true" class="save-button"(click)="onCreateClick()" ><mat-icon>add</mat-icon>Create new stream.</button>