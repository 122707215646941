<div class="container">
    <div class="title-container">
        <h1>Admin Management</h1>
    </div>
    <button mat-raised-button class="add-button" (click)="openAddAdminDialog()" >Add <mat-icon>add</mat-icon></button>
    <table mat-table [dataSource]="admins" class="mat-elevation-z8">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Discord ID </th>
          <td mat-cell *matCellDef="let admin"> {{admin.id}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let admin"> {{admin.name}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let admin">     
              <button mat-raised-button class="remove-button"  (click)="openAreYouSure(admin.id)" disabled="{{admin.id==160516803678699520 || admin.id == user!.id}}">Remove</button>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
</div>