<h1 mat-dialog-title>Add a new admin</h1>
<div mat-dialog-content>
  <p>Discord ID of the new admin</p>
  <mat-form-field appearance="fill">
    <mat-label>Discord ID</mat-label>
    <input matInput [(ngModel)]="id" >
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="remove-button" [mat-dialog-close]="null">Cancel</button>
  <button mat-raised-button [mat-dialog-close]="id" class="add-button" cdkFocusInitial>Add</button>
</div>