<div class="container">
    <div class="title-container">
        <h1>Api Key Management</h1>
    </div>
    <button mat-raised-button class="add-button" (click)="newApiKeyClick()">Add <mat-icon>add</mat-icon></button>
    <table mat-table [dataSource]="apiKeys" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let key"> {{key.name}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let key">     
            <button mat-raised-button class="copy-button" (click)="onCopyClick(key.token)" >Copy Token <mat-icon>content_copy</mat-icon></button>
            <button mat-raised-button class="remove-button" (click)="onRemoveClick(key.token)">Remove</button>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
</div>