import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
    gif: string = "";
    private gifs = [
        "assets/404/superman.gif",
        "assets/404/nba.gif",
        "assets/404/some_women.gif",
        "assets/404/telescope.gif",
        "assets/404/disney.gif"
    ]
    constructor() { }

    ngOnInit(): void {
        this.gif = this.gifs[Math.floor(Math.random()*this.gifs.length)]
    }

}
